import { defineStore } from "pinia";
import { useNuxtApp, showError } from "nuxt/app";
import { $useFetch } from "~/composables/useFetch";

export const useCulturesStore = defineStore("cultures", {
	state: () => ({
		cultures: null,
	}),
	actions: {
		async fetchSectionTree() {
			const { api } = useNuxtApp();

			const { error } = await $useFetch(api.cultures, {
				method: "GET",
				onResponse({ response }) {
					if (response?.status === 200) useCulturesStore().cultures = response._data.data;
				},
			});

			if (error.value) {
				throw showError({ statusCode: 500, statusMessage: error.value.message });
			}
		},
		getCultureQualitiesById(id) {
			return this.cultures[id].qualities || [];
		},
		getCultureById(id) {
			return this.cultures[id].name || "";
		},
		getTypeById(id) {
			return this.cultures[id].type || "";
		},
	},
	getters: {
		getCultures() {
			return this.cultures;
		},
		getCulturesNamed() {
			const culturesByName = [];

			for (const item in this.cultures) {
				culturesByName.push(this.cultures[item].name);
			}
			return culturesByName;
		},
		getCultureIdByName: (state) => (name) => {
			for (const item in state.cultures) {
				if (state.cultures[item].name === name) return state.cultures[item].id;
			}
		},
		getCultureNameById: (state) => (id) => state.cultures[id].name || "",
		getCultureType: (state) => (id) => state.cultures[id].type || "",
	},
});
